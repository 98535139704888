import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../../components/Layout';

import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import filePdf from '../../assets/files/Australia guide 2022.pdf';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  return (
    <Layout title="Thank you for your interest!" description="Many thanks for your downloading!">
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Thank you for downloading the Spanish visa guide for Australian citizens!
            </h1>
            <div className="subsection">
              <p className="subsection__par">
                Your download will start in few seconds. If this doesn't happen,{' '}
                <a className="red-link" href={filePdf} download="Australia guide 2022.pdf">
                  click here
                </a>{' '}
                to get your copy.
              </p>
              <p className="subsection__par">The TEFL Iberia Team</p>
              <Link to="/" className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
